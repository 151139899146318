var exports = {};
var utils = exports;

function toArray(msg, enc) {
  if (Array.isArray(msg)) return msg.slice();
  if (!msg) return [];
  var res = [];

  if (typeof msg !== "string") {
    for (var i = 0; i < msg.length; i++) res[i] = msg[i] | 0;

    return res;
  }

  if (enc === "hex") {
    msg = msg.replace(/[^a-z0-9]+/ig, "");
    if (msg.length % 2 !== 0) msg = "0" + msg;

    for (var i = 0; i < msg.length; i += 2) res.push(parseInt(msg[i] + msg[i + 1], 16));
  } else {
    for (var i = 0; i < msg.length; i++) {
      var c = msg.charCodeAt(i);
      var hi = c >> 8;
      var lo = c & 255;
      if (hi) res.push(hi, lo);else res.push(lo);
    }
  }

  return res;
}

utils.toArray = toArray;

function zero2(word) {
  if (word.length === 1) return "0" + word;else return word;
}

utils.zero2 = zero2;

function toHex(msg) {
  var res = "";

  for (var i = 0; i < msg.length; i++) res += zero2(msg[i].toString(16));

  return res;
}

utils.toHex = toHex;

utils.encode = function encode(arr, enc) {
  if (enc === "hex") return toHex(arr);else return arr;
};

export default exports;